import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { CardProvider } from "./hook/useSelectedContext.tsx";
import axios from "axios";

export const api = axios.create({
  baseURL: "https://expresscreditcardshop.onrender.com/",
  withCredentials: true,
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <CardProvider>
      <App />
    </CardProvider>
  </StrictMode>
);
