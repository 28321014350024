import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface PaymentMethodProps {
  paymentMethods: string[];
  selectedPaymentMethod: string;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
  handlePaymentMethod: (paymentMethod: string) => void;
}

const PaymentContext = createContext<PaymentMethodProps | undefined>(undefined);

// PaymentProvider handles payment functionality
export const PaymentProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");

  const paymentMethods: string[] = ["Telecel", "GCB", "USDT", "BitCoin"];

  const handlePaymentMethod = (paymentMethod: string) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    if (selectedPaymentMethod) {
      window.location.href = "/complete-order-awx91-2aeF";

      // store in the local storage
    }
  }, [selectedPaymentMethod]);

  const value = useMemo(
    () => ({
      paymentMethods,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      handlePaymentMethod,
    }),
    [selectedPaymentMethod]
  );

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};

// Custom hook to use payment context
export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePaymentContext must be used within a PaymentProvider");
  }
  return context;
};
