import { lazy, Suspense } from "react";
const Home = lazy(() => import("./pages/Home"));
const Payment = lazy(() => import("./pages/payment"));
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import "./App.css";
import { PaymentProvider } from "./hook/usePaymentContext";
import ToastContainers from "./components/toast/ToastContainers";
const App = () => {
  const router = createBrowserRouter([
    {
      // error page
      path: "*",
      element: (
        <div className="notFound">
          <div className="content__error">
            <h1 className="text-2xl text-center">Oops! Page Not Found</h1>
            <p className="text-base text-center text-neutral-800 font-medium">
              Sorry, the page you are looking for does not exist.
            </p>
            <div className="error-btn">
              <button>
                <Link to={"/"}>Return Home</Link>
              </button>
            </div>
          </div>
          {/* You can add a link back to home or other pages */}
        </div>
      ),
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/complete-order-awx91-2aeF",
      element: <Payment />,
    },
  ]);
  return (
    <Suspense fallback={<div className="loader"></div>}>
      <PaymentProvider>
        <ToastContainers />
        <RouterProvider router={router}></RouterProvider>
      </PaymentProvider>
    </Suspense>
  );
};

export default App;
