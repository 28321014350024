import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useMemo,
} from "react";

interface CardContainer {
  // interface cardContainer
  id: number;
  type: string;
  price: number;
  limit: number;
}

interface CardSelectedProps {
  selectedCards: CardContainer[];
  setSelectedCards: React.Dispatch<React.SetStateAction<CardContainer[]>>;
  handleAddToCart: (
    id: number,
    type: string,
    price: number,
    limit: number
  ) => void;
}

// Create contexts
const SelectedContext = createContext<CardSelectedProps | undefined>(undefined);

// CardProvider handles cart functionality
export const CardProvider = ({ children }: { children: ReactNode }) => {
  const [selectedCards, setSelectedCards] = useState<CardContainer[]>([]);

  const handleAddToCart = (
    id: number,
    type: string,
    price: number,
    limit: number
  ) => {
    setSelectedCards((prev) => {
      const findIndexPro = prev.findIndex((card) => card.id === id);
      if (findIndexPro !== -1) {
        return prev.filter((card) => card.id !== id);
      } else {
        return [...prev, { id, type, price, limit }];
      }
    });
  };

  const value = useMemo(
    () => ({
      selectedCards,
      setSelectedCards,
      handleAddToCart,
    }),
    [selectedCards]
  );

  return (
    <SelectedContext.Provider value={value}>
      {children}
    </SelectedContext.Provider>
  );
};

// Custom hook to use card context
const useGlobalSelectContext = () => {
  const context = useContext(SelectedContext);
  if (!context) {
    throw new Error(
      "useGlobalSelectContext must be used within a CardProvider"
    );
  }
  return context;
};

export default useGlobalSelectContext;
